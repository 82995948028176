import React from "react";
import "./Home.css"
import { Link } from "react-router-dom";




const Home = () => {
    return (
        <div >


            <h1>
                <Link to="/home">
                    <img className='logobec' src="img/logobec.jpg" alt="Logo" />
                </Link>

            </h1>
            <div className="hidden">
                <h1>
                    B.E.C Mécanique
                </h1>
            </div>




            <div className="firstline">
                <ul>
                    <li><Link to="/Etude-realisation" className="link">ETUDE ET REALISATION D'EQUIPEMENTS DE PRODUCTION SUR MESURE</Link></li>
                    <li><Link to="/Prestation" className="link">PRESTATION EN INGENIERIE MECANIQUE</Link></li>
                    <li><Link to="/Machines-speciales" className="link">ETUDE DE MACHINES SPECIALES</Link></li>
                </ul>
            </div>

            <div className="secondline">
                <div>
                    <img src="img/img1.jpg" alt="" />
                    <h2>Ajout contrôle caméra<br /> sur ligne de conditionnement <br />agro-alimentaire</h2>
                </div>
                <div>
                    <img src="img/img2.jpg" alt="" />
                    <h2>Gabarit de montage</h2>
                </div>
                <div>
                    <img src="img/img3.jpg" alt="" />
                    <h2>Cobot lecture de codes-barres</h2>
                </div>
            </div>

            <div className="secondline">
                <div>
                    <img src="img/img4.jpg" alt="" />
                    <h2>Machine automatique d'assemblage</h2>
                </div>
                <div>
                    <img src="img/img5.jpg" alt="" />
                    <h2>Cellule robot de dévracage</h2>
                </div>
                <div>
                    <img src="img/img6.jpg" alt="" />
                    <h2>Poste d'échantillonage</h2>
                </div>
            </div>
            <div className="footer">B.E.C Mécanique - 6 rue Gustave Stoskopf - 67520 Marlenheim - 06.78.06.75.34 - <a href="mailto:info@bec-mecanique.fr">info@bec-mecanique.fr </a></div>
            <div className="footer-red">S.A.R.L au capital de 5 000 Euros - CS de Saverne TI 844 054 791 - SIRET 844 054 791 00018</div>
        </div>
    );
}

export default Home;

