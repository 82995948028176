
import './App.css';
import Home from './components/Home.js';
import Etude from './components/Etude.js';
import { Route, Routes, BrowserRouter as Router } from "react-router-dom";
import Prestation from './components/Prestation';
import Special from './components/Special';


function App() {
  return (
    <Router>
        <div>
            <Routes>
                <Route index element={<Home />} />
                <Route path="/home" element={<Home />} />
                <Route path="/Etude-realisation" element={<Etude />} />
                <Route path="/Prestation" element={<Prestation />} />
                <Route path="/Machines-speciales" element={<Special />} />
            </Routes>
        </div>
    </Router>
  );
}

export default App;
