import React from 'react';
import "./Prestation.css"
import { Link } from "react-router-dom";
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll';


const Prestation = () => {
    return (
        <div>
            <h1>
                <Link to="/home">
                    <img className='logobec' src="img/logobec.jpg" alt="Logo" />
                </Link>
            </h1>

            <div className="hidden">
                <h1>
                    B.E.C Mécanique
                </h1>
            </div>
            <div className='special-title'><strong>Votre Partenaire de Confiance en prestation </strong></div>

            <div className='intro'>Votre mission est de concrétiser des idées novatrices. Notre mission est de vous y aider. Avec une expertise approfondie en ingénierie mécanique, nous sommes parfaitement équipés pour transformer vos concepts en designs fonctionnels et optimisés. Nous sommes plus qu'un prestataire de services, nous sommes le prolongement de votre bureau d'études, engagés à propulser votre projet vers le succès.
            </div>

            <div className='prestation-page'>
                <div className="services-section">
                    <h2>Types de prestation :</h2>
                    <ul>
                        <li>
                            <strong>Conception et Mise en Plans: </strong>
                            Exploitez la puissance des logiciels de modélisation 3D avancés tels que Créo et Solidworks, pour donner vie à vos idées. Notre équipe d'ingénieurs qualifiés est prête à travailler à vos côtés, sur site ou en externalisation, selon ce qui convient le mieux à votre projet.
                        </li>
                        <li>
                            <strong>Gestion de Projets: </strong>
                            La réussite de votre projet est notre priorité absolue. Nous adoptons une approche proactive pour anticiper et résoudre les défis, garantissant une exécution fluide de la conception à la réalisation.
                        </li>
                        <li>
                            <strong>Collaboration Flexible: </strong>
                            Que vous ayez besoin de notre expertise sur site ou que vous préfériez externaliser certaines fonctions, nous sommes flexibles et prêts à nous adapter à vos exigences.
                        </li>
                    </ul>
                </div>
                <div className="engagement-section">
                    <h2>Engagez-Nous Aujourd'hui Pour:</h2>
                    <ul>
                        <li>
                            Un processus de conception collaborative qui encourage l'innovation et garantit que tous les aspects de votre projet sont bien alignés avec vos objectifs.
                        </li>
                        <li>
                            Une gestion de projet rigoureuse qui assure que votre projet reste sur la bonne voie, respecte les délais et les budgets.
                        </li>
                        <li>
                            Une tranquillité d'esprit totale, sachant que votre projet est entre de mains expertes, dédiées à la réalisation de vos ambitions.
                        </li>
                    </ul>
                </div>
            </div>

            <div className='splide-container'>
                <Splide
                    options={{
                        type: "loop",

                        drag: "free",
                        arrows: false,

                        pagination: false,
                        perPage: 4,

                        autoScroll: {
                            pauseOnHover: true,
                            pauseOnFocus: false,
                            interval: 0,
                            speed: 2,
                        },
                        breakpoints: {
                            480: {
                                perPage: 2,
                                gap: '3px'
                            },
                        }
                    }}
                    extensions={{ AutoScroll }}
                >
                    <SplideSlide>
                        <img src='./img/img1.jpg' alt='' />
                    </SplideSlide>
                    <SplideSlide>
                        <img src='./img/img2.jpg' alt='' />
                    </SplideSlide>
                    <SplideSlide>
                        <img src='./img/img3.jpg' alt='' />
                    </SplideSlide>
                    <SplideSlide>
                        <img src='./img/img4.jpg' alt='' />
                    </SplideSlide>
                    <SplideSlide>
                        <img src='./img/img5.jpg' alt='' />
                    </SplideSlide>
                    <SplideSlide>
                        <img src='./img/img6.jpg' alt='' />
                    </SplideSlide>
                </Splide>

            </div>

            <div className="footer-etude">Chez B.E.C. Mécanique, notre mission est de vous offrir des solutions d'ingénierie mécanique de premier ordre. Avec notre expertise et notre dévouement, nous sommes prêts à relever tous les défis. <a href="mailto:info@bec-mecanique.fr">Contactez nous  </a> dès aujourd'hui et donnez vie à vos projets !

            </div>

        </div>
    );
}

export default Prestation;
