import React from 'react';
import "./Special.css"
import { Link } from "react-router-dom";
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll';

const Special = () => {
    return (
        <div>
            <h1>
                <Link to="/home">
                    <img className='logobec' src="img/logobec.jpg" alt="Logo" />
                </Link>
            </h1>

            <div className="hidden">
                <h1>
                    B.E.C Mécanique
                </h1>
            </div>

            <div className='special-title'><strong>Votre Partenaire de Confiance pour la Conception de Machines Spéciales</strong></div>
            <div className='special-description'>La mécanique est l’art de l’innovation où chaque projet représente un défi unique. Dans cette optique, nous nous spécialisons dans l’étude et la conception de machines spéciales, en utilisant les outils de pointe Créo et Solidworks pour matérialiser vos besoins en des solutions robustes et fiables. Notre engagement est de fournir des prestations de haute qualité adaptées à votre cahier des charges, à un tarif forfaitaire compétitif.
            </div>
            <div className='special-offer'>
                <ul>
                    <li>
                        <strong>Élaboration de Concepts Innovants:</strong><br /> Nous traduisons votre vision en modèle 3D précis, prêts  à être intégrés dans votre chaine de production.
                    </li>
                    <li>
                        <strong>Livraison Complète:</strong><br /> Vous recevrez un jeu de plans détaillés, les fichiers 3D et les nomenclatures nécessaires pour la réalisation et la maintenance de votre machine.
                    </li>
                    <li>
                        <strong>Tarification Transparente</strong><br />Un prix forfaitaire convenu dès le depart, vous assurant une maîtrise totale des coûts sans surprise.
                    </li>
                </ul>
            </div>
            <div className='special-flex'>
                <div className='special-approach'>Exemple de secteurs d'activités :</div>
                <div className='special-sectors'>
                    <ul>
                        <li>
                            <strong>Automobile:</strong><br />Conception de machines spécialisées pour l'assemblage, le contrôle et la production automatisée.
                        </li>
                        <li>
                            <strong>Pharmaceutique:</strong><br /> Développement de systèmes mécaniques précis pour la manipulation et le conditionnement de produits pharmaceutiques.
                        </li>
                        <li>
                            <strong>Agroalimentaire</strong><br />Élaboration de machines pour le traitement, le conditionnement et la distribution de produits alimentaires.
                        </li>

                    </ul>

                </div>
            </div>

            <div className='special-footer'>
                Faites le premier pas vers la concrétisation de votre projet mécanique en nous contactant dès aujourd'hui. Notre équipe d'experts est prête à vous accompagner pour réaliser des machines spéciales qui répondent à vos exigences et contribuent à l'optimisation de votre processus de production. Nous sommes impatients de collaborer avec vous pour créer des solutions mécaniques sur-mesure qui propulseront votre entreprise vers de nouveaux horizons.
            </div>

            <div className='splide-container'>
                <Splide
                    options={{
                        type: "loop",

                        drag: "free",
                        arrows: false,

                        pagination: false,
                        perPage: 4,

                        autoScroll: {
                            pauseOnHover: true,
                            pauseOnFocus: false,
                            interval: 0,
                            speed: 2,
                        },
                        breakpoints: {
                            480: {
                                perPage: 2,
                                gap: '3px'
                            },
                        }
                    }}
                    extensions={{ AutoScroll }}
                >
                    <SplideSlide>
                        <img src='./img/img1.jpg' alt='' />
                    </SplideSlide>
                    <SplideSlide>
                        <img src='./img/img2.jpg' alt='' />
                    </SplideSlide>
                    <SplideSlide>
                        <img src='./img/img3.jpg' alt='' />
                    </SplideSlide>
                    <SplideSlide>
                        <img src='./img/img4.jpg' alt='' />
                    </SplideSlide>
                    <SplideSlide>
                        <img src='./img/img5.jpg' alt='' />
                    </SplideSlide>
                    <SplideSlide>
                        <img src='./img/img6.jpg' alt='' />
                    </SplideSlide>
                </Splide>

            </div>


            <div className="footer-etude">Chez B.E.C. Mécanique, notre mission est de vous offrir des solutions d'ingénierie mécanique de premier ordre. Avec notre expertise et notre dévouement, nous sommes prêts à relever tous les défis. <a href="mailto:info@bec-mecanique.fr">Contactez nous  </a> dès aujourd'hui et donnez vie à vos projets !

            </div>
        </div>
    );
}

export default Special;
