
import "./Etude.css"


import { Link } from "react-router-dom";
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll';






const Etude = () => {



    return (
        <div>
            <h1>
                <Link to="/home">
                    <img className='logobec' src="img/logobec.jpg" alt="Logo" />
                </Link>
            </h1>
            <div className="hidden">
                <h1>
                    B.E.C Mécanique
                </h1>
            </div>

            <div className='special-title'><strong>Votre Partenaire de Confiance en fourniture d'équipements sur mesure clés en mains !</strong></div>
            <div className="presentation-description1">Étude et réalisation d'équipements de production sur mesure : Nous transformons votre besoin en réalité. Chaque projet est unique, et nous nous engageons à fournir des solutions personnalisées qui répondent précisément à vos besoins, comme par exemple :</div>
            <div className="presentation-container">
                <div className="presentation-description">Gabarits de montage</div>
                <div className="presentation-description">Gabarits de brasage</div>
                <div className="presentation-description">Postes de travail</div>
                <div className="presentation-description">Améliorations / retrofit</div>
            </div>


            <div className='splide-container'>
                <Splide
                    options={{
                        type: "loop",

                        drag: "free",
                        arrows: false,

                        pagination: false,
                        perPage: 4,

                        autoScroll: {
                            pauseOnHover: true,
                            pauseOnFocus: false,
                            interval: 0,
                            speed: 2,
                        },
                        breakpoints: {
                            480: {
                                perPage: 2,
                                gap: '3px'
                            },
                        }
                    }}
                    extensions={{ AutoScroll }}
                >
                    <SplideSlide>
                        <img src='./img/img1.jpg' alt='' />
                    </SplideSlide>
                    <SplideSlide>
                        <img src='./img/img2.jpg' alt='' />
                    </SplideSlide>
                    <SplideSlide>
                        <img src='./img/img3.jpg' alt='' />
                    </SplideSlide>
                    <SplideSlide>
                        <img src='./img/img4.jpg' alt='' />
                    </SplideSlide>
                    <SplideSlide>
                        <img src='./img/img5.jpg' alt='' />
                    </SplideSlide>
                    <SplideSlide>
                        <img src='./img/img6.jpg' alt='' />
                    </SplideSlide>
                </Splide>

            </div>


            <div className="footer-etude">Chez B.E.C. Mécanique, notre mission est de vous offrir des solutions d'ingénierie mécanique de premier ordre. Avec notre expertise et notre dévouement, nous sommes prêts à relever tous les défis. <a href="mailto:info@bec-mecanique.fr">Contactez nous  </a> dès aujourd'hui et donnez vie à vos projets !

            </div>

        </div>
    );
}

export default Etude;
